<template>
  <ValidationObserver @submit="addRoom">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="dashboard">Add Room</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiInput
            v-model="room.title"
            type="text"
            name="Room Title"
            title="Room Title"
            label="Title"
            placeholder="Room Title"
            class="flex-1"
            rules="required"
          />

          <UiSingleSelect
            v-model="room.campus_id"
            title="Campus"
            label="title"
            :options="campuses"
            class="flex-1"
            rules="required"
            :disabled="!!currentCampusScope"
            reduce="id"
            @change="getDepartment"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Save</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

import { mapState, mapActions } from 'vuex'
export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    UiInput,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      room: {
        title: '',
        department_id: '',
        campus_id: '',
      },
    }
  },
  computed: {
    ...mapState('layout', ['campuses', 'isLoading', 'currentCampusScope']),
    ...mapState('instituteCount', ['instituteCount']),
  },
  mounted() {
    if (this.currentCampusScope) {
      this.room.campus_id = this.currentCampusScope.id
    }
  },

  methods: {
    ...mapActions('room', ['createRoom']),
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },
    async addRoom() {
      try {
        this.$store.commit('layout/IS_LOADING', true)
        await this.createRoom(this.room).then(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'rooms_count')
        })
        this.$emit('toggle', 'fetch')
      } catch (error) {
        const message = this.$t(`toast.Room Already Exists!`)
        this.$store.commit('toast/NEW', { message: message, type: 'error' }, { root: true })
      }
    },
  },
}
</script>
