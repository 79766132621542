import roomData from '@/src/router/views/rooms/Room.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (recordsLength) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: roomData.text,
          value: recordsLength,
          tooltipDescription: roomData.tooltipDescription,
        },
      ],
    },
  ]
  return stats
}
