<template>
  <div>
    <RoomFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>

    <div v-else-if="!isLoading && isEmpty(roomsList)">
      <NoRecordFound />
    </div>

    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="head in tableHeaders" :key="head">
            <span v-i18n="dashboard">
              {{ head }}
            </span>
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(room, index) in roomsList" :key="index">
          <TD>
            {{ room.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(roomCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <RoomModal v-if="modalRoom" :modal="modalRoom" @toggle="roomModalToggle"></RoomModal>
  </div>
</template>

<script>
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapState, mapActions } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { isEmpty } from 'lodash'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import GeneralMixin from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import RoomModal from '@src/router/views/admin/modals/RoomModal.vue'
import RoomFilter from '@src/router/views/rooms/RoomFilter.vue'
import fileMixins from '@/src/mixins/file-mixins'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import scrollMixin from '@src/mixins/scroll-mixin'
import roomData from '@src/router/views/rooms/Room.json'
import { rightBarStats } from '@src/router/views/rooms/Room.ulit.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    RoomFilter,
    Pagination,
    Loader,
    RoomModal,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      filtersData: {},
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      dashboard: 'dashboard',
      errorMsg: false,
      options: ['Select'],
      roomsList: [],
      showPagination: false,
      tableHeaders: roomData.tableHeads,
      filteredRecordLimit: 10,
      roomCounts: null,
      modalRoom: false,
    }
  },
  page: roomData.page,
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
  },
  watch: {
    showModal: {
      handler() {
        this.roomModalToggle()
      },
    },
  },
  created() {
    this.setRightbar()
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    roomModalToggle(payload) {
      this.modalRoom = !this.modalRoom
      if (payload === 'fetch') this.filterRecord()
    },
    filterRecord(range) {
      this.isLoading = true
      let paginationRange = paginationRangeHandler(range)

      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getRooms(payload)
        .then((response) => {
          this.roomsList = response.records
          this.roomCounts = response.meta.total_records
          this.showPagination = this.roomCounts > 10
          this.setRightbar(response.meta.total_records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setRightbar(recordsLength = 0) {
      this.setRightbarContent({
        header: {
          title: 'RV_RB_HT',
          buttons: [
            {
              title: 'RV_RB_BT',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
          ],
        },
        stats: rightBarStats(recordsLength),
      })
    },
    ...mapActions('room', ['getRooms']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>
